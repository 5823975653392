form {
    display: flex;
    background-color: #00b5e2;
    justify-content: center;
    padding: 1%;  
}

input {
    padding: 1%;
    border: 0;
    border-radius: 2%;
    width: 15em;
    margin: 2%;
}
   
button {
    width: 10em;
    margin-top: 2%;
    margin-bottom: 2%;
    border-style: solid;
    color: white;
    background-color: #00b5e2;
    border-color: white;
    transition-duration: 0.4s;
} 

button:hover {
    background-color: white;
    color: #00b5e2
  }
    