* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body, 
#root {
  height: 100%;
}