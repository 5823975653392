.tableContainer {
  width: 50%;
  display: inline-block;
  
}

table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 90%;
    margin-left: 10%;
    color: grey;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  .participation {
    text-align: center
  }
  